import React from 'react';
import { InterviewCard, Grid } from '../ui';

const InterviewsCardsGrid = ({ posts }) => {
  return (
    <Grid>
      {posts.map(({ node: post }) => (
        <InterviewCard key={post.id} post={post} />
      ))}
    </Grid>
  );
};

export default InterviewsCardsGrid;
