import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Pagination from "../components/Pagination";
import { Container, H1 } from "../ui";
import Helmet from "react-helmet";

import InterviewsCardsGrid from "../components/InterviewsCardsGrid";
import { randomNumber } from "../utils/helpers";

export const interviewsPageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "interview-post" } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 180)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  layout: CONSTRAINED
                  placeholder: DOMINANT_COLOR
                )
              }
            }
            templateKey
            description
            color
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

const InterviewsPage = ({ data, location, pageContext }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const url = "https://gymlovers.pt";
  const description = "Inpira-te a treinar com as entrevistas do Gymlovers";
  const image = "/img/share.png";
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <Layout location={location} interview={posts[randomNumber(posts.length)]}>
      <Helmet>
        <title>Entrevistas | Gymlovers</title>
        <meta name="description" content={description} />

        <meta itemprop="name" content="Entrevistas | Gymlovers" />
        <meta itemprop="description" content={description} />
        <meta itemprop="image" content={`${url}${image}`} />

        <meta property="og:url" content={`${url}/entrevistas/`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Entrevistas | Gymlovers" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${url}${image}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Entrevistas | Gymlovers" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${url}${image}`} />
      </Helmet>
      <InterviewsPageTemplate
        posts={posts}
        numPages={numPages}
        currentPage={currentPage}
        previousPath={isFirst ? null : `/entrevistas/${prevPage}`}
        nextPath={isLast ? null : `/entrevistas/${nextPage}`}
      />
    </Layout>
  );
};

const InterviewsPageTemplate = ({
  posts,
  previousPath,
  nextPath,
  numPages,
  currentPage,
}) => {
  return (
    <Container mb={[5, 6]}>
      <H1>Entrevistas</H1>
      <InterviewsCardsGrid posts={posts} />
      <Pagination
        previousPath={previousPath}
        nextPath={nextPath}
        numPages={numPages}
        currentPage={currentPage}
      />
    </Container>
  );
};

export default InterviewsPage;
