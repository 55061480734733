import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pagination = ({ previousPath, nextPath, currentPage, numPages }) => (
  
  <Flex alignItems="center" justifyContent="space-between" mx={[0, "auto"]} mt={[4, 5]} inverted={previousPath === ''} width={["100%", "400px"]}>
    <div>
      {previousPath && (
        <NumLink to={previousPath}>
          <FontAwesomeIcon icon={['fas', 'angle-left']} size="xl" />
        </NumLink>
      )}
    </div>
    <Flex justifyContent="space-between" alignItems="center" width={["100%", "300px"]}>
      {Array.from({ length: numPages }, (_, i) => (
        <NumLink key={`pagination-number${i + 1}`} to={`/entrevistas/${i === 0 ? "" : i + 1}`} mx={[0, 1]} active={currentPage === i + 1}>{i + 1}</NumLink>
      ))}
    </Flex>
    <div>
      {nextPath && (
        <NumLink to={nextPath}>
          <FontAwesomeIcon icon={['fas', 'angle-right']} size="xl" />
        </NumLink>
      )}
    </div>
  </Flex>
);

export default Pagination;

const NumLink = styled(Link)`
  text-decoration: none;
  font-family: ${props => props.theme.fonts.family};
  font-weight: 500;
  font-size: 18px;
  padding: 4px 11px;
  background-color: ${props => props.active ? props.theme.colors.blue : props.theme.colors.white};
  color: ${props => props.active ? props.theme.colors.white : props.theme.colors.blue};
  border-radius: 10px;

  &:hover {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white };
  }
`;